
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

@Options({})
export default class LoggedUserLayout extends Vue {

    created() {
    }

    get isUrlChallenge() {
        return this.$route.fullPath.indexOf("challenge") > -1;
    }

}
